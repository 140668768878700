var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: {
        "header-bg-variant": "dark",
        "body-bg-variant": "light",
        "header-text-variant": "white",
        id: _vm.id,
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c(
          "span",
          {
            attrs: { id: "crewApp.delete.title" },
            domProps: { textContent: _vm._s(_vm.$t("entity.delete.title")) },
          },
          [_vm._v("Confirm delete operation")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "p",
          {
            attrs: { id: "jhi-delete-celula-heading" },
            domProps: { textContent: _vm._s(_vm.$t("entity.delete.question")) },
          },
          [_vm._v("Are you sure you want to delete this Celula?")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary",
            attrs: { type: "button" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", id: "jhi-confirm-delete-celula" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.delete")) },
            on: {
              click: function ($event) {
                return _vm.remove()
              },
            },
          },
          [_vm._v("\n      Delete\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }