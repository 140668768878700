var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pantalla" },
    [
      _c("b-overlay", {
        staticClass: "cargando",
        attrs: { show: "" },
        scopedSlots: _vm._u([
          {
            key: "overlay",
            fn: function () {
              return [
                _c("b-spinner", {
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "primary" },
                }),
                _vm._v(" "),
                _c("h5", {
                  staticClass: "mt-4 text-center",
                  staticStyle: { color: "white" },
                  domProps: { textContent: _vm._s(_vm.mensaje) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }