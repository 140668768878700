import { Authority } from '@/shared/security/authority';

const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiGatewayComponent = () => import('@/admin/gateway/gateway.vue');

export default [
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/gateway',
    name: 'JhiGatewayComponent',
    component: JhiGatewayComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
];
