var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          attrs: {
            id: "cancel-save",
            type: "button",
            variant: "outline-primary",
          },
          on: {
            click: function ($event) {
              return _vm.previousState()
            },
          },
        },
        [
          _c(
            "span",
            {
              domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
            },
            [_vm._v("Cancel")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { type: "submit", variant: "primary", disabled: _vm.enabled },
        },
        [
          _vm.saving
            ? _c("b-spinner", { attrs: { small: "", type: "grow" } }, [
                _vm._v("Guardando..."),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.saving ? _c("span", [_vm._v("Guardando...")]) : _vm._e(),
          _vm._v(" "),
          !_vm.saving
            ? _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("entity.action.save")),
                  },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }